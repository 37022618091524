<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="transparent"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    height="70"
    flat
  >
    <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-toolbar-title class="font-weight-bold text-h2 ml-5" v-text="name" />

    <v-spacer />

    <!-- <default-search class="hidden-sm-and-down" /> -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          class="ml-3 mr-4"
          elevation="1"
          fab
          small
          v-on="on"
          @click="$webSerialApi.startConnection()"
        >
          <v-icon :color="status ? '#45e689' : '#f82328'">mdi-scale </v-icon>
        </v-btn>
      </template>
      <span>{{
        status
          ? "Connection to the scale is open"
          : "Connection to the scale is closed"
      }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          class="ml-3 mr-4"
          elevation="1"
          fab
          small
          v-on="on"
          @click="$webSerialPrinterApi.startConnection()"
        >
          <v-icon :color="printerStatus ? '#45e689' : '#f82328'"
            >mdi-printer-pos
          </v-icon>
        </v-btn>
      </template>
      <span>{{
        printerStatus
          ? "Connection to the printer is open"
          : "Connection to the printer is closed"
      }}</span>
    </v-tooltip>
    <!-- <default-go-home /> -->
    <default-account />
    <!-- <default-notifications /> -->
  </v-app-bar>
</template>

<script>
// Utilities
import { get, sync } from "vuex-pathify";

export default {
  name: "DefaultBar",
  components: {
    DefaultAccount: () =>
      import(
        /* webpackChunkName: "default-account" */
        "./widgets/Account Admin"
      ),
    DefaultDrawerToggle: () =>
      import(
        /* webpackChunkName: "default-drawer-toggle" */
        "./widgets/DrawerToggle"
      ),
    DefaultGoHome: () =>
      import(
        /* webpackChunkName: "default-go-home" */
        "./widgets/GoHome"
      ),
    // DefaultNotifications: () =>
    // import(
    //   /* webpackChunkName: "default-notifications" */
    //   './widgets/Notifications'
    // ),
    // DefaultSearch: () =>
    // import(
    //   /* webpackChunkName: "default-search" */
    //   './widgets/Search'
    // ),
  },
  data: () => ({
    status: false,
    printerStatus: false,
  }),
  computed: {
    ...sync("app", ["drawer", "mini"]),
    name: get("route/name"),
  },
  created() {
    this.status = this.$webSerialApi.getStatus();
    this.status = this.$webSerialPrinterApi.getStatus();
    this.$webSerialApi.hub.$on("status", (status) => {
      this.status = status;
    });
    this.$webSerialPrinterApi.hub.$on("status", (status) => {
      this.printerStatus = status;
    });
  },
  beforeDestroy() {
    this.$webSerialApi.hub.$off("status", (status) => {
      this.status = status;
    });
    this.$webSerialPrinterApi.hub.$off("status", (status) => {
      this.printerStatus = status;
    });
  },
  methods: {
    onSignalRConnectionChanged(isConnected) {
      if (isConnected) {
        this.currentGlobe = this.greenGlobe;
        this.currentGlobeId = 1;
      } else {
        this.currentGlobe = this.redGlobe;
        this.currentGlobeId = 0;
      }
    },
  },
};
</script>
