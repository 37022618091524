<template>

  <v-app-bar id="default-app-bar" app absolute class="v-bar--underline" color="transparent" :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl" height="70" flat>

    <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-toolbar-title class="font-weight-bold text-h2 ml-5" v-text="menuName" />

    <v-spacer />

    <!-- <default-search class="hidden-sm-and-down" /> -->
    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn class="ml-3 mr-4" elevation="1" fab small v-on="on">
          <v-icon :color="currentGlobe">mdi-web</v-icon>
        </v-btn>
      </template>
      <span>{{
        currentGlobeId == 1
          ? "Mobile server working"
          : "Mobile server not working"
      }}</span>
    </v-tooltip> -->
    <!-- <default-go-home /> -->

    <!-- <default-notifications /> -->

    <!-- <v-row style="padding-top: 15px; width: 100px;">
      <v-autocomplete clearable persistent-hint label="Search..." prepend-icon="mdi-magnify" v-model="searchProduct"
        :items="menus" item-text="subDepartmentName"></v-autocomplete>

      <v-btn class="mx-2" fab dark small color="primary" @click="searchSub(searchProduct)">
        <v-icon dark>
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </v-row> -->

    <default-account />

  </v-app-bar>
</template>

<script>
// Utilities
import { get, sync } from "vuex-pathify";
import { mapState, mapMutations } from "vuex";

export default {
  name: "DefaultBar",
  components: {
    DefaultAccount: () =>
      import(
        /* webpackChunkName: "default-account" */
        "./widgets/Account"
      ),
    DefaultDrawerToggle: () =>
      import(
        /* webpackChunkName: "default-drawer-toggle" */
        "./widgets/DrawerToggle"
      ),
    DefaultGoHome: () =>
      import(
        /* webpackChunkName: "default-go-home" */
        "./widgets/GoHome"
      ),
    // DefaultNotifications: () =>
    // import(
    //   /* webpackChunkName: "default-notifications" */
    //   './widgets/Notifications'
    // ),
    // DefaultSearch: () =>
    // import(
    //   /* webpackChunkName: "default-search" */
    //   './widgets/Search'
    // ),
  },
  data: () => ({
    currentGlobe: "#f82328",
    greenGlobe: "#45e689",
    redGlobe: "#f82328",
    currentGlobeId: 0,

    searchProduct: "",
    searchItem: [],
    menus: [],
    itemId: "",
  }),
  computed: {
    ...mapState(["menuName"]),

    ...sync("app", ["drawer", "mini"]),
    name: get("route/name"),
  },
  created() {
    this.getMenu();
  },

  methods: {
    onSignalRConnectionChanged(isConnected) {
      if (isConnected) {
        this.currentGlobe = this.greenGlobe;
        this.currentGlobeId = 1;
      } else {
        this.currentGlobe = this.redGlobe;
        this.currentGlobeId = 0;
      }
    },

    getMenu() {
      this.$http
        .get("/Menus")
        .then(response => {
          // var item = response.data.items;
          // this.searchItem = item.filter(
          //   (a) => a.subDepartmentId === this.itemId
          // );
          this.menus = response.data;
        })
        .catch(error => {
          console.log("Error");
          if (error.response.status) {
            this.$toast.error("Error in getting Data", "Error", {
              position: "topRight"
            });

          }
        });
    },


    closeSearch() {
      this.searchProduct = '';
      this.itemId = '';
      // this.activateSearch = false;
    },

    async searchSub(val) {
      var pagination = {
        pageNumber: 1,
        pageSize: 100,
        search: val,
      };

      await this.$http
        .post("/Menus/Products/Search", pagination)
        .then(response => {
          // var item = response.data.items;
          // this.searchItem = item.filter(
          //   (a) => a.subDepartmentId === this.itemId
          // );
          this.itemId = response.data.items[0].subDepartmentId;
          window.location.href = '/sub-department/' + this.itemId;
          console.log(this.itemId)
        })
        .catch(error => {
          console.log("Error");
          if (error.response.status) {
            this.$toast.error("Error in getting Data", "Error", {
              position: "topRight"
            });

          }
        });
    },
  },
};
</script>
